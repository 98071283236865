import React, { useState, useEffect } from 'react';

import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Button,
	Badge,
	Divider,
	Dialog,
	MenuItem,
	Menu,
	Fade,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
} from '@material-ui/core';
import { AccountCircle, SpeakerNotes, ExitToApp, Language } from '@material-ui/icons';
import { LanguageIcon } from '@material-ui/icons/Language';
import { connect } from 'react-redux';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { STORE, getStateVariables } from '../redux/selectors';
import NotificationList from './NotificationList';
import i18n from '../i18n';
import EditPopup from './EditPopup';
import * as colors from '../colors';
import { updateUserInfo } from '../redux/actionCreators';
import { set } from 'date-fns';

const WEAK_PASSWORD = `weakPassword
passwordRequirements:
- min 12 characters
- at least one uppercase letter 
- at least one lowercase letter 
- at least one number`;

const SET_LANGUAGE = gql`
	mutation ($id: String!, $add: Boolean!, $language: String!) {
		addOrRemoveKeycloakUserAttributes(id: $id, add: $add, language: $language) {
			language
		}
	}
`;

const GET_NOTIFICATIONS = gql`
	query {
		getSensorNotificationLog {
			notificationlogid
			sensorid
			timestamp
			lowerthreshold
			upperthreshold
			sensorvalue
		}
	}
`;
const SET_NOTIFICATION_READ = gql`
	mutation ($ids: [String!]!, $lastReadNotificationId: Int!) {
		setKeycloakUsers(ids: $ids, lastReadNotificationId: $lastReadNotificationId) {
			id
		}
	}
`;
const SET_KEYCLOAK_USERS = gql`
	mutation ($ids: [String!]!, $email: String, $phonenumber: String, $firstName: String, $lastName: String, $password: String) {
		setKeycloakUsers(
			ids: $ids
			email: $email
			phonenumber: $phonenumber
			firstName: $firstName
			lastName: $lastName
			password: $password
		) {
			id
			username
			email
			phonenumber
			firstName
			lastName
		}
	}
`;

function Notify(props) {
	const [lang, setLang] = React.useState(i18n.language);
	const [showUserInfo, setShowUserInfo] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);
	const [notificationData, setNotificationData] = useState([]);
	const [oldLastReadNotificationId, setOldLastReadNotificationId] = useState(Number.MAX_SAFE_INTEGER);
	const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
	const [getNotifications, notificationsQuery] = useLazyQuery(GET_NOTIFICATIONS);
	const [setNotificationRead] = useMutation(SET_NOTIFICATION_READ);
	const [showPasswordPopup, setShowPasswordPopup] = useState(false);
	const [updateUserPopup, setUpdateUserPopup] = useState(false);
	const [usersUnderEdit, setUsersUnderEdit] = useState([]);
	const [showSuccessPopup, setShowSuccessPopup] = useState(false);
	const [successPasswordPopup, setSuccessPasswordPopup] = useState(false);
	const [currentPassword, setCurrentPassword] = useState('');
	const [currentPasswordMatch, setCurrentPasswordMatch] = useState(false);

	const [setLanguage] = useMutation(SET_LANGUAGE);

	//const logoutUrl = 'https://login.novint.me/auth/realms/connect/protocol/openid-connect/logout?redirect_uri=' + window.location.origin;
	const logoutUrl = 'https://login.meliox.se/auth/realms/connect/protocol/openid-connect/logout?redirect_uri=' + window.location.origin;

	const { t } = useTranslation();

	function isStrongPassword(password) {
		return password.length >= 12 && password.match(/[a-z]/) && password.match(/[A-Z]/) && password.match(/[0-9]/);
	}

	useEffect(() => {
		getNotifications();
		// eslint-disable-next-line
	}, []);
	//const [setKeycloakUsers] = useMutation(SET_KEYCLOAK_USERS);
	const [setKeycloakUsers] = useMutation(SET_KEYCLOAK_USERS, {
		onCompleted: ({ setKeycloakUsers }) => {
			if (setKeycloakUsers[0]) {
				props.updateUserInfo(setKeycloakUsers[0]);
			}
		},
	});

	useEffect(() => {
		if (oldLastReadNotificationId === Number.MAX_SAFE_INTEGER && props.userInfo?.lastReadNotificationId)
			setOldLastReadNotificationId(props.userInfo?.lastReadNotificationId);
		// eslint-disable-next-line
	}, [props.userInfo]);

	useEffect(() => {
		if (!notificationsQuery.loading && !notificationsQuery.error && notificationsQuery.data) {
			setNotificationData(
				notificationsQuery.data.getSensorNotificationLog
					?.filter(not => props.userInfo?.sensorSubscriptions?.includes(Number(not.sensorid)))
					.sort((a, b) => (Number(a.notificationlogid) > Number(b.notificationlogid) ? -1 : 1))
					.map(not => ({
						...(props.sensors.find(sen => Number(sen.sensorid) === Number(not.sensorid)) || {}),
						...not,
					}))
					.filter(not => not.locationid) || []
			);
		}
		// eslint-disable-next-line
	}, [notificationsQuery.data, props.sensors]);

	function toggleUserInfoPopup() {
		setShowUserInfo(!showUserInfo);
		setShowNotifications(false);
	}

	function toggleNotificationsPopup() {
		setShowNotifications(!showNotifications);
		setShowUserInfo(false);

		// Update Keycloak user's lastReadNotificationId when the notifications popup is opened
		const maxLogId = notificationData.reduce((a, b) => Math.max(Number(a.notificationlogid || a), Number(b.notificationlogid || b)), 0);
		if (!showNotifications && maxLogId && maxLogId > (props.userInfo?.lastReadNotificationId || -1)) {
			setNotificationRead({
				variables: {
					ids: [props.userInfo?.id || ''],
					lastReadNotificationId: maxLogId,
				},
			});
			props.userInfo.lastReadNotificationId = maxLogId;
		}
	}

	const handleLanguageChange = event => {
		// Set the new language in the i18next instance
		setLang(String(event.target.value));
		setLanguage({
			variables: {
				id: props.userInfo.id,
				add: false,
				language: String(event.target.value),
			},
		}).then(() => {
			console.log(event.target.value);
			i18n.changeLanguage(event.target.value)
				.then(() => {
					// Once the language is changed, update the translations manually
					updateTranslations();
				})
				.catch(error => {
					console.error('Failed to change language:', error);
				});
		});
	};

	// Function to update the translations manually
	const updateTranslations = () => {
		// Perform any necessary logic to update the translations
		// For example, you can re-fetch translation resources based on the new language
		// and update the i18next instance with the new translations
		window.location.reload(); // TODO: Look in constants.js for explination.
	};
	useEffect(() => {
		const checkPassword = async () => {
			const client_id = process.env.REACT_APP_KEYCLOAK_CLIENTID;//'connect-front';
			const realm =process.env.REACT_APP_KEYCLOAK_REALM; //'connect';
			const keycloak_url =process.env.REACT_APP_KEYCLOAK_URL; //'https://login.meliox.se/auth';
			const auth_endpoint = `${keycloak_url}/realms/${realm}/protocol/openid-connect/token`;

			const data = {
				client_id: client_id,
				username: props?.userInfo?.username,
				password: currentPassword,
				grant_type: 'password',
			};
			const config = {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			};
			try {
				const response = await axios.post(auth_endpoint, new URLSearchParams(data), config);
				if (response.status === 200) {
					//console.log('Password is correct');
					setCurrentPasswordMatch(true);
				} else {
					//console.log('Password is incorrect');
					setCurrentPasswordMatch(false);
				}
			} catch (error) {
				//console.log('Failed to check password', error);
				setCurrentPasswordMatch(false);
			}
		};
		checkPassword();
	}, [currentPassword]);

	return (
		<div>
			<div style={{ width: '100%' }}>
				<List>
					<ListItem button onClick={toggleNotificationsPopup}>
						<ListItemIcon style={{ color: '#eee', marginLeft: '0.6rem' }}>
							<Badge
								badgeContent={
									notificationData.filter(
										dat => Number(dat.notificationlogid) > (props.userInfo?.lastReadNotificationId || -1)
									).length
								}
								color='error'
							>
								<SpeakerNotes />
							</Badge>
						</ListItemIcon>
						<ListItemText primary={t('notify.notifications')} />
					</ListItem>

					<ListItem button onClick={toggleUserInfoPopup}>
						<ListItemIcon style={{ color: '#eee', marginLeft: '0.6rem' }}>
							<AccountCircle />
						</ListItemIcon>
						<ListItemText primary={t('generic.user')} />
					</ListItem>

					<a href={logoutUrl} style={{ textDecoration: 'none', color: '#fff' }}>
						<ListItem button href={logoutUrl}>
							<ListItemIcon style={{ color: '#eee', marginLeft: '0.6rem' }}>
								<ExitToApp />
							</ListItemIcon>
							<ListItemText primary={t('notify.logOut')} />
						</ListItem>
					</a>
				</List>
			</div>
			<Dialog open={showNotifications} onClose={toggleNotificationsPopup} maxWidth='lg'>
				<NotificationList
					data={notificationData}
					oldLastReadNotificationId={oldLastReadNotificationId}
					closeList={() => setShowNotifications(false)}
				/>
			</Dialog>

			<Dialog open={showUserInfo} onClose={toggleUserInfoPopup}>
				<div style={{ minWidth: '16rem', margin: '1rem' }}>
					<h2 style={{ fontWeight: '400', padding: '0.5rem 0 0.5rem 0', textAlign: 'center' }}>{t('notify.userInfo')}</h2>

					<Divider style={{ margin: '0 0.25rem' }} />

					<div style={{ display: 'flex' }}>
						<p style={{ color: '#888', fontSize: '110%', lineHeight: '175%', margin: '1rem 0.5rem' }}>
							{t('generic.name')}:
							<br />
							{t('notify.userName')}:
							<br />
							{t('generic.email')}:
							<br />
							{t('notify.phoneNumber')}:
						</p>
						<p style={{ fontSize: '110%', lineHeight: '175%', margin: '1rem 0.5rem' }}>
							{(props.userInfo?.firstName || '') + ' ' + (props.userInfo?.lastName || '')}
							<br />
							{props.userInfo?.username || ''}
							<br />
							{props.userInfo?.email || ''}
							<br />
							{props.userInfo?.phonenumber || ''}
						</p>
					</div>

					<Divider style={{ margin: '0 0.25rem' }} />

					<FormControl fullWidth variant='filled'>
						<InputLabel id='demo-simple-select-outlined-label'>Language</InputLabel>
						<Select
							labelId='demo-simple-select-outlined-label'
							id='demo-simple-select-outlined'
							value={lang}
							onChange={handleLanguageChange}
							label='Language'
						>
							<MenuItem value={'sv'}>Svenska</MenuItem>
							<MenuItem value={'en'}>English</MenuItem>
						</Select>
					</FormControl>
				</div>
				{props.userInfo.authorizationAccess === 'all' || props.userInfo.authorizationAccess === 'parents' ? null : (
					<>
						<Button
							variant='outlined'
							onClick={() => {
								setUsersUnderEdit([props.userInfo]);
								setUpdateUserPopup(!updateUserPopup);
							}}
							style={{ marginBottom: '1.7rem', marginLeft: '1rem', marginRight: '1rem', color: colors.primary}}
						>
							{t('userAdmin.UpdateUserDetails')}
						</Button>
						<Button
							variant='outlined'
							onClick={() => {
								setPasswordErrorMessage('');
								setShowPasswordPopup(!showPasswordPopup);
							}}
							style={{ marginBottom: '1.7rem', marginLeft: '1rem', marginRight: '1rem', color: colors.primary}}
						>
							{t('userAdmin.changePassword')}
						</Button>
					</>
				)}
			</Dialog>
			{/* User edit popup */}
			<EditPopup
				isOpen={updateUserPopup}
				text={{
					title: t('userAdmin.UpdateUserDetails'),
				}}
				fields={[
					{
						id: 'email',
						label: t('generic.email'),
						placeholder: usersUnderEdit.length === 1 ? usersUnderEdit[0].email : '...',
						disabled: usersUnderEdit.length > 1,
					},
					{
						id: 'phonenumber',
						label: t('generic.phoneNumber'),
						placeholder: usersUnderEdit.length === 1 ? usersUnderEdit[0].phonenumber : '...',
						disabled: usersUnderEdit.length > 1,
						inputmode: 'numeric',
						type: 'tel',
						pattern: '[0-9]*',
					},
					{
						id: 'firstName',
						label: t('generic.firstName'),
						placeholder: usersUnderEdit.length === 1 ? usersUnderEdit[0].firstName : '...',
						disabled: usersUnderEdit.length > 1,
					},
					{
						id: 'lastName',
						label: t('generic.lastName'),
						placeholder: usersUnderEdit.length === 1 ? usersUnderEdit[0].lastName : '...',
						disabled: usersUnderEdit.length > 1,
					},
				]}
				onClose={() => {
					setUpdateUserPopup(!updateUserPopup);
				}}
				onSave={vals => {
					setKeycloakUsers({ variables: { ids: [props.userInfo.id], ...vals } });
					setUpdateUserPopup(!updateUserPopup);
					setShowSuccessPopup(!showSuccessPopup);
				}}
			/>
			{/* Success popup for user information*/}
			<Dialog open={showSuccessPopup} onClose={() => setShowSuccessPopup(!showSuccessPopup)}>
				<div style={{ minWidth: '16rem', margin: '1rem' }}>
					<h2 style={{ fontWeight: '400', padding: '0.5rem 0 0.5rem 0', textAlign: 'center' }}>{t('userAdmin.UpdateUserDetails')}</h2>
					<Divider style={{ margin: '0 0.25rem' }} />
					<p style={{ color: '#888', fontSize: '110%', lineHeight: '175%', margin: '1rem 0.5rem' }}>
						{t('userAdmin.saveInfo')}
					</p>
				</div>
			</Dialog>
			{/* Success popup for password change*/}
			<Dialog open={successPasswordPopup} onClose={() => setSuccessPasswordPopup(!successPasswordPopup)}>
				<div style={{ minWidth: '16rem', margin: '1rem' }}>
					<h2 style={{ fontWeight: '400', padding: '0.5rem 0 0.5rem 0', textAlign: 'center' }}>{t('userAdmin.changePassword')}</h2>
					<Divider style={{ margin: '0 0.25rem' }} />
					<p style={{ color: '#888', fontSize: '110%', lineHeight: '175%', margin: '1rem 0.5rem' }}>
						{t('userAdmin.savePassword')}
					</p>
				</div>
			</Dialog>
			{/* Password edit popup */}
			<EditPopup
				isOpen={showPasswordPopup}
				text={{
					title: t('userAdmin.changePassword'),
					subtitle: t('userAdmin.provideNewPasswordUser'),
					error: passwordErrorMessage,
				}}
				fields={[
					{
						id: 'currentPassword',
						label: t('userAdmin.currentPassword'),
						elementType: 'password',
						required: true,
						onChange: (e) => {
							//e.prevntDefault();
							setCurrentPassword(e?.target?.value || e);
						}
					},
					{
						id: 'password',
						label: t('userAdmin.newPassword'),
						elementType: 'password',
						required: true,
						required_rule_text: WEAK_PASSWORD,
						required_rule: password => !isStrongPassword(password),
					},
					{
						id: 'confirmPassword',
						label: t('userAdmin.confirmNewPassword'),
						elementType: 'password',
						required: true,
					},
				]}
				onClose={() => {
					setShowPasswordPopup(!showPasswordPopup);
				}}
				onSave={vals => {
					if (currentPasswordMatch) {
						//current password matches with new password
						if (vals.password === currentPassword) {
							setPasswordErrorMessage(t('userAdmin.newPasswordSame'));
						} else if (vals.password === vals.confirmPassword) {
							setKeycloakUsers({ variables: { ids: [props.userInfo.id], password: vals.password } });
							setShowPasswordPopup(!showPasswordPopup);
						setSuccessPasswordPopup(!successPasswordPopup);
					} else {
						setPasswordErrorMessage(t('userAdmin.newPasswordNotSame'));
					}
				} else {
					setPasswordErrorMessage(t('userAdmin.currentPasswordNotSame'));
				}
				}}
			/>
		</div>
	);
}

export default connect(getStateVariables(STORE.userInfo, STORE.sensors), { updateUserInfo })(Notify);
